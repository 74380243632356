import {useLocation} from '@remix-run/react';
import {useMemo} from 'react';

import type {FeatureFlags} from '@rockawayxlabs/observatory-utils';

import {useRouteData} from '~/features/serialization';
import {URLParams} from '~/utils/URLParams';

import type {loader} from '../../root';
import {getFeatureFlags} from './flags';

export function useFeatureFlags(): FeatureFlags {
  const {env} = useRouteData<typeof loader>('root');
  const {search} = useLocation();

  return useMemo(() => {
    const urlParams = URLParams.fromSearch(search);

    return getFeatureFlags(env, urlParams);
  }, [env, search]);
}

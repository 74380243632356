import type {FeatureFlags} from '@rockawayxlabs/observatory-utils';
import {getFeatureFlagsFromEnv} from '@rockawayxlabs/observatory-utils';

import type {URLParams} from '~/utils/URLParams';

type Env = Record<string, string | undefined>;

function mergeFeatureFlags(defaultFlags: FeatureFlags, ...flags: FeatureFlags[]) {
  return flags.reduce<FeatureFlags>((previousFlags, flag) => {
    return {
      nodeClaim: previousFlags.nodeClaim || flag.nodeClaim,
      validatorOutages: previousFlags.validatorOutages || flag.validatorOutages,
      validatorScoreHistory: previousFlags.validatorScoreHistory || flag.validatorScoreHistory,
    };
  }, defaultFlags);
}

export function getFeatureFlags(env: Env, urlParams: URLParams<string>): FeatureFlags {
  const envFeatureFlags = getFeatureFlagsFromEnv(env);
  const urlFeatureFlags = urlParams.getFeatureFlags();

  return mergeFeatureFlags(envFeatureFlags, urlFeatureFlags);
}
